@import '../assets/colors';
.loginWrapper {
  position: fixed;
  z-index: 200;
  max-width: 341px;
  margin-top: 82px;
  background: $pink;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.6);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 0px;
  padding-left: 16px;
  padding-right: 16px;

  h1,
  p {
    text-align: center;
    line-height: 120%;
    color: $brown;
  }
  small {
    color: $brown;
  }
  p {
    font-size: 16px;
    font-family: 'Work Sans';
    line-height: 150%;
    margin-bottom: 32px;
  }

  h1 {
    font-size: 32px;
  }
  img {
    margin: 32px;
    margin-bottom: 0px;
  }
  input[type='text'],
  input[type='email'] {
    width: 310px;
    height: 48px;
    border-radius: 6px;
    background: transparent;
    border: 1px #2f2926 solid;
    padding-left: 16px;
    font-size: 16px;
    font-family: 'Work Sans';
  }
  input[type='text'],
  input[type='email'] {
    margin-bottom: 16px;
  }

  button {
    margin: 40.77px;
    border-radius: 50px;
    background: $brown;
    border: none;
    color: #fff;
    width: 248px;
    height: 55px;
    font-family: 'PT Serif';
    font-size: 24px;
    line-height: 120%;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.loginWrapper > form:nth-child(4) > label:nth-child(5) {
  align-self: start;
  margin-left: 10px;
}

.loginWrapper > form:nth-child(4) > label:nth-child(5) > input:nth-child(1) {
  margin-right: 6px;
}
