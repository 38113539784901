@import '../assets/colors';
.cartWrapper {
  // height: 400px;
  color: $brown;
  max-width: 341px;
  position: absolute;
  background: #fff;
  z-index: 500;
  top: 72px;
  right: 17px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  ul {
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
  }
  ul::-webkit-scrollbar {
    display: none;
  }
  .emptyCart {
    margin-top: 32px;
    margin-bottom: 32px;
    color: $brown;
  }
  .upArrow {
    width: 8px;
    height: 4.7px;
    cursor: pointer;
  }
  .downArrow {
    cursor: pointer;
    width: 8px;
    height: 4.7px;
    transform: rotate(180deg);
  }
  .totalAmountWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    img {
      overflow: hidden;
      height: 31.3333px;
      object-position: 0px 9px;
      max-width: 100%;
    }
  }
  h1 {
    margin-top: 40px;
    font-size: 32px;
    margin-bottom: 40px;
  }
  button {
    background: $brown;
    font-family: PT Serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    border-radius: 50px;
    color: #fff;
    width: 248px;
    height: 55px;
    margin-bottom: 32px;
    cursor: pointer;
    margin-top: 32px;
    transition: background 0.2s ease-in;
    &:hover {
      background: red;
    }
  }
  ul {
    padding: 0;
    width: 100%;
    li > div {
      display: flex;
      justify-content: space-between;
      width: 100%;
      // height: 40px;
    }
  }
  .dots {
    overflow: hidden;
    // object-position: 0px -4px;
    width: 318px;

    flex-grow: 1;
  }
  .divDots {
    width: 100%;
    height: 100%;

    flex-grow: 1;
    overflow: hidden;
  }
  .cartTitleWrapper {
    flex-shrink: 0;
  }
  .amountPicker {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 10px;
  }
  .polygon {
    height: 16px;
    width: 16px;
    position: absolute;
    right: 16px;
    top: -10px;
    z-index: 800;
  }
}
