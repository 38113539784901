@import '../assets/colors';

.menuWrapper {
  background: #f3e4e1;
  min-height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  .cartIconButton {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 100;
  }
  .cartIconButton:hover {
    cursor: pointer;
    color: red;
  }

  .circle {
    position: absolute;
    top: 16px;
    right: 12px;
    background: #e5674e;
    z-index: 664;
    border-radius: 50%;
    height: 22px;
    width: 22px;
    color: white;
    text-align: center;
  }
  h1 {
    font-size: 42px;
    text-align: center;
    line-height: 120%;
    color: $brown;
    margin-top: 119px;
  }

  .menuList {
    max-width: 311px;

    li {
      list-style-type: none;
      display: flex;
      align-items: center;

      .listTextWrapper {
        min-width: 100%;
      }
      .listFlex {
        display: flex;
        justify-content: space-between;
        width: 278px;
      }
      h3 {
        font-family: PT Serif;
        font-style: normal;
        font-weight: bold;
        font-size: 23px;
        line-height: 120%;
        color: $brown;
        flex-shrink: 0;
      }

      .dotsMenu {
        flex-grow: 1;
        width: 100%;
        overflow: hidden;
        img {
          width: 200px;
          height: 100%;

          object-position: 0px 7px;
          object-fit: fill;
        }
      }
      small {
        color: $brown;
      }
    }
  }
  button {
    background: transparent;
    border: none;

    height: 32px;
  }
  .addButton {
    height: 32px;
    width: 32px;
    cursor: pointer;
  }
  footer {
    position: absolute;
    bottom: -20px;
    width: 100%;
    height: 73px;
    img {
      object-fit: cover;
      height: 73px;
      object-position: 50% 0%;
    }
  }
  .menuList
    > li:nth-child(7)
    > div:nth-child(2)
    > div:nth-child(1)
    > h3:nth-child(1) {
    font-size: 21px;
  }
}
