@import url(https://fonts.googleapis.com/css2?family=PT+Serif:wght@700&family=Work+Sans&display=swap);
*{margin:0;padding:0;box-sizing:border-box}html,body{margin:0;font-family:'PT Serif', 'Work Sans';-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}small{font-family:'Work sans';font-size:12px;line-height:130%}

.App {
  height: 100vh;
}

.loginWrapper{position:fixed;z-index:200;max-width:341px;margin-top:82px;background:#f3e4e1;box-shadow:0px 0px 32px rgba(0,0,0,0.6);border-radius:3px;display:flex;flex-direction:column;align-items:center;padding-bottom:0px;padding-left:16px;padding-right:16px}.loginWrapper h1,.loginWrapper p{text-align:center;line-height:120%;color:#2f2926}.loginWrapper small{color:#2f2926}.loginWrapper p{font-size:16px;font-family:'Work Sans';line-height:150%;margin-bottom:32px}.loginWrapper h1{font-size:32px}.loginWrapper img{margin:32px;margin-bottom:0px}.loginWrapper input[type='text'],.loginWrapper input[type='email']{width:310px;height:48px;border-radius:6px;background:transparent;border:1px #2f2926 solid;padding-left:16px;font-size:16px;font-family:'Work Sans'}.loginWrapper input[type='text'],.loginWrapper input[type='email']{margin-bottom:16px}.loginWrapper button{margin:40.77px;border-radius:50px;background:#2f2926;border:none;color:#fff;width:248px;height:55px;font-family:'PT Serif';font-size:24px;line-height:120%}.loginWrapper form{display:flex;flex-direction:column;align-items:center}.loginWrapper>form:nth-child(4)>label:nth-child(5){align-self:start;margin-left:10px}.loginWrapper>form:nth-child(4)>label:nth-child(5)>input:nth-child(1){margin-right:6px}

.profileWrapper{background:#2f2926;height:100%;display:flex;justify-content:center}.orderWrapper{margin-top:123px;color:#fff}.orderWrapper .profileInfo{margin-bottom:65px;text-align:center;width:315px}.orderWrapper .profileInfo h2{margin-top:16px;font-size:24px;line-height:120%}.orderWrapper .profileInfo h4{font-family:Work Sans;font-style:normal;font-weight:normal;font-size:14px;line-height:120%;color:rgba(255,255,255,0.8)}.orderWrapper ul li{list-style-type:none;display:flex;justify-content:space-between}.orderWrapper ul li .totalOrderSum{text-align:right}.orderWrapper ul .lineThin{margin:8px 0;width:100%;border:1px solid rgba(255,255,255,0.08)}.orderWrapper ul .totalOrderSumRight{text-align:right}.orderWrapper .line{margin:16px 0px;border:1px solid rgba(255,255,255,0.6);width:100%}.orderWrapper .orderId{font-family:Work Sans;font-style:normal;font-weight:bold;font-size:14px;line-height:120%;color:rgba(255,255,255,0.7)}.orderWrapper .orderDate{font-family:Work Sans;font-style:normal;font-weight:normal;font-size:14px;line-height:120%}.orderWrapper .totalOrderSum{font-family:Work Sans;font-size:12px}.orderWrapper .totalSpent{display:flex;justify-content:space-between;font-family:Work Sans;font-style:normal;font-weight:bold;font-size:14px;line-height:120%}

.menuWrapper{background:#f3e4e1;min-height:100%;overflow:hidden;position:relative;display:flex;flex-direction:column;align-items:center}.menuWrapper .cartIconButton{position:absolute;right:0px;top:0px;z-index:100}.menuWrapper .cartIconButton:hover{cursor:pointer;color:red}.menuWrapper .circle{position:absolute;top:16px;right:12px;background:#e5674e;z-index:664;border-radius:50%;height:22px;width:22px;color:white;text-align:center}.menuWrapper h1{font-size:42px;text-align:center;line-height:120%;color:#2f2926;margin-top:119px}.menuWrapper .menuList{max-width:311px}.menuWrapper .menuList li{list-style-type:none;display:flex;align-items:center}.menuWrapper .menuList li .listTextWrapper{min-width:100%}.menuWrapper .menuList li .listFlex{display:flex;justify-content:space-between;width:278px}.menuWrapper .menuList li h3{font-family:PT Serif;font-style:normal;font-weight:bold;font-size:23px;line-height:120%;color:#2f2926;flex-shrink:0}.menuWrapper .menuList li .dotsMenu{flex-grow:1;width:100%;overflow:hidden}.menuWrapper .menuList li .dotsMenu img{width:200px;height:100%;object-position:0px 7px;object-fit:fill}.menuWrapper .menuList li small{color:#2f2926}.menuWrapper button{background:transparent;border:none;height:32px}.menuWrapper .addButton{height:32px;width:32px;cursor:pointer}.menuWrapper footer{position:absolute;bottom:-20px;width:100%;height:73px}.menuWrapper footer img{object-fit:cover;height:73px;object-position:50% 0%}.menuWrapper .menuList>li:nth-child(7)>div:nth-child(2)>div:nth-child(1)>h3:nth-child(1){font-size:21px}

.cartWrapper{color:#2f2926;max-width:341px;position:absolute;background:#fff;z-index:500;top:72px;right:17px;box-shadow:0px 0px 16px rgba(0,0,0,0.25);border-radius:3px;display:flex;justify-content:space-between;flex-direction:column;align-items:center;padding-left:16px;padding-right:16px}.cartWrapper ul{max-height:300px;overflow-y:scroll;overflow-x:hidden;scrollbar-width:none}.cartWrapper ul::-webkit-scrollbar{display:none}.cartWrapper .emptyCart{margin-top:32px;margin-bottom:32px;color:#2f2926}.cartWrapper .upArrow{width:8px;height:4.7px;cursor:pointer}.cartWrapper .downArrow{cursor:pointer;width:8px;height:4.7px;transform:rotate(180deg)}.cartWrapper .totalAmountWrapper{width:100%;display:flex;justify-content:space-between;position:relative}.cartWrapper .totalAmountWrapper img{overflow:hidden;height:31.3333px;object-position:0px 9px;max-width:100%}.cartWrapper h1{margin-top:40px;font-size:32px;margin-bottom:40px}.cartWrapper button{background:#2f2926;font-family:PT Serif;font-style:normal;font-weight:bold;font-size:24px;line-height:120%;border-radius:50px;color:#fff;width:248px;height:55px;margin-bottom:32px;cursor:pointer;margin-top:32px;transition:background 0.2s ease-in}.cartWrapper button:hover{background:red}.cartWrapper ul{padding:0;width:100%}.cartWrapper ul li>div{display:flex;justify-content:space-between;width:100%}.cartWrapper .dots{overflow:hidden;width:318px;flex-grow:1}.cartWrapper .divDots{width:100%;height:100%;flex-grow:1;overflow:hidden}.cartWrapper .cartTitleWrapper{flex-shrink:0}.cartWrapper .amountPicker{display:flex;flex-direction:column;justify-content:space-between;align-items:center;height:10px}.cartWrapper .polygon{height:16px;width:16px;position:absolute;right:16px;top:-10px;z-index:800}

.statusWrapper{background:#e5674e;text-align:center;min-height:100%;padding-top:140px;color:#fff}.statusWrapper h1{font-family:PT Serif;font-style:normal;font-weight:bold;font-size:36px;line-height:120%}.statusWrapper h2{font-family:Work Sans;font-style:normal;font-weight:bold;font-size:22px;line-height:120%;margin-bottom:60px;margin-top:16px}.statusWrapper h3{font-family:Work Sans;font-style:normal;font-weight:normal;font-size:16px;line-height:120%;margin-bottom:40px}.statusWrapper img{margin-bottom:40px;-webkit-animation:hover 5s alternate infinite;animation:hover 5s alternate infinite;perspective:600px;transform-style:preserve-3d}.statusWrapper button{border:none;border-radius:50px;width:184px;height:55px;background:#fff;font-family:PT Serif;font-style:normal;font-weight:bold;font-size:24px;line-height:120%;cursor:pointer}@-webkit-keyframes hover{0%{transform:translateX(50px) translateY(20px) rotate3d(0, 1, 0, 6deg)}50%{transform:translateX(20px)}75%{transform:translateY(40px)}100%{transform:translateX(-100px) rotate3d(0, 1, 0, 10deg) translateY(-20px) ro}}@keyframes hover{0%{transform:translateX(50px) translateY(20px) rotate3d(0, 1, 0, 6deg)}50%{transform:translateX(20px)}75%{transform:translateY(40px)}100%{transform:translateX(-100px) rotate3d(0, 1, 0, 10deg) translateY(-20px) ro}}

.aboutWrapper{padding-top:140px;padding-left:32px;padding-right:32px;display:flex;justify-content:center;flex-direction:column;align-items:center;background:#f3e4e1;min-height:100vh;position:relative}.aboutWrapper .contentWrapper{max-width:611px}.aboutWrapper h1{font-size:42px;margin-bottom:17px}.aboutWrapper p{font-family:'Work Sans';margin-bottom:16px;font-size:14px}.aboutWrapper .ownerWrapper{margin-top:32px;text-align:center;margin-bottom:100px}.aboutWrapper .ownerWrapper h3{font-size:35px;font-weight:bold}.aboutWrapper .ownerWrapper h5{font-size:22px;font-family:'Work Sans';font-weight:normal}

footer{bottom:0px;width:100vw;height:73px}footer img{position:absolute;object-fit:cover;height:73px;width:100%;object-position:50% 0%}

.navFlowers{z-index:4;position:fixed;width:100%;height:113px;object-fit:cover;object-position:50% 100%}.buttonMenu{position:fixed;left:16px;top:16px;background:none;border:none;z-index:888;display:block}.buttonMenu:hover{cursor:pointer}.navWrapper{position:fixed;width:100vw;height:100vh;display:flex;justify-content:center;align-items:center;z-index:600;background:#2f2926;transform:translateX(-100vw);transition:transform 0.4s ease-in}.navWrapper .navMain ul{display:flex;flex-direction:column;align-items:center;justify-content:center}.navWrapper .navMain ul a{color:#fff;text-decoration:none;font-size:32px}.navWrapper .navMain ul a:hover{color:#0e927d}.navWrapper .navMain li{list-style:none;text-align:center;height:45 px}.navWrapper .navMain .line{width:48px;height:1px;background:rgba(255,255,255,0.2);border-radius:2px;margin:32px}.showNav{transform:translateX(0);transition:transform 0.35s linear}

